import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "../other-components/TopBar";
import LoadingDialog from "../dialogs/LoadingDialog/dialog";
import { API_ACCESS_URL, generateAuthToken } from "../modals/Constants";
import { deleteStorage, getStorage } from "../modals/Storage";
import general from "../css/General.module.css";

function NewSubordinate() {
  const navigate = useNavigate();
  const [isDataLoaded, setDataLoaded] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const [pageConst, setConstants] = useState({
    pageTitle: "New subordinate",
    recordList: [],
  });

  const topBarClickAction = (data) => {
    if (data == "multiBtn1") {
      navigate("/withdraw", { replace: false });
    }
  };

  const updateLoadingStatus = (data) => {
    setShowLoading(data);
  };

  const updateRecordList = (data) => {
    let tempData = [];

    for (let i = 0; i < data.length; i++) {
      tempData.push(
        <div key={i} className="col-view pd-10-15 mg-t-20 br-b-l-grey">
          <div className="row-view sb-view">
            <span className="cl-grey ft-sz-16">{data[i]["m_mobile"]}</span>
            <span className="cl-grey ft-sz-16">UID: {data[i]["m_id"]}</span>
          </div>

          <div className="row-view sb-view mg-t-10">
            <span className="ft-sz-16">{Number(data[i]["m_account_level"]) == 1 ? 'Direct subordinates' : 'Team subordinates'}</span>
            <span className="cl-grey ft-sz-16">{data[i]["m_joined"]}</span>
          </div>
        </div>
      );
    }

    setConstants((previousState) => {
      return { ...previousState, recordList: tempData };
    });
  };

  function getAllMembers() {
    const requestAPI = async (url) => {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Route: "route-allmember-records",
            AuthToken: generateAuthToken(Number(getStorage("uid"))),
          },
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if ((data.status_code = "success")) {
          setDataLoaded(true);
          updateRecordList(data.data);
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    };

    updateLoadingStatus(true);
    requestAPI(
      API_ACCESS_URL + "?USER_ID=" + getStorage("uid") + "&PAGE_NUM=1&TYPE=" + selectedTab
    );
  }

  useEffect(() => {
    if (isDataLoaded) {
      getAllMembers();
    }
  }, [selectedTab]);

  useEffect(() => {
    if (!getStorage("uid")) {
      if (deleteStorage()) {
        navigate("/login", { replace: true });
      }
    } else {
      getAllMembers();
    }
  }, []);

  return (
    <div className="v-center bg-black">
      <div className="h-100vh pr-v res-wth ovf-scrl-y hide-sb bg-white">
        <TopBar
          intentData={pageConst}
          multiBtn={true}
          multiBtn1=""
          multiBtn2=""
          updateState={topBarClickAction}
        />
        <LoadingDialog intentData={showLoading} />

        <div className="col-view mg-t-45">
          <div className="col-view br-right-t br-left-t mg-b-15">
            <div className="col-view pd-5-15">
              <div className={[general.rowView, general.mgT10px].join(" ")}>
                <div className={[general.pd5px10px, general.borderRadius10px, selectedTab == 0 ? `${general.bgCustomColor1} ${general.colorWhite}` : general.bgLightGrey].join(" ")} onClick={() => setSelectedTab(0)}>Today</div>
                <div className={[general.pd5px10px, general.borderRadius10px, general.mgL10px, selectedTab == 1 ? `${general.bgCustomColor1} ${general.colorWhite}` : general.bgLightGrey].join(" ")} onClick={() => setSelectedTab(1)}>Yesterday</div>
                {/* <div className={[general.pd5px10px, general.borderRadius10px, general.mgL10px, selectedTab == 2 ? `${general.bgCustomColor1} ${general.colorWhite}` : general.bgLightGrey].join(" ")} onClick={() => setSelectedTab(2)}>This month</div> */}
              </div>
              <div className="col-view">{pageConst.recordList}</div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default NewSubordinate;