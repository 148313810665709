import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "../other-components/TopBar";
import LoadingDialog from "../dialogs/LoadingDialog/dialog";
import { API_ACCESS_URL, copyText, generateAuthToken } from "../modals/Constants";
import { deleteStorage, getStorage } from "../modals/Storage";
import ToastDialog from "../dialogs/ToastDialog/toast";
import general from "../css/General.module.css";

function SubordinateData() {
  const navigate = useNavigate();
  const toastDialogRef = useRef(null);
  const [showLoading, setShowLoading] = useState(false);

  const [depositAmount, setTotalDepositAmount] = useState(0);
  const [depositNumber, setTotalDepositNumber] = useState(0);
  const [betAmount, setTotalBetAmount] = useState(0);
  const [betNumber, setTotalBetNumber] = useState(0);

  const [firstDeposit, setTotalFirstDepositNumber] = useState(0);
  const [firstDepositAmount, setTotalFirstDepositAmount] = useState(0);

  const [pageConst, setConstants] = useState({
    pageTitle: "Subordinate Data",
    recordList: [],
  });

  // toast component
  const onToastChange = (data, msg) => { };

  const showToast = (message, duration, delay, mode) => {
    toastDialogRef.current.openDialog(message, duration, delay, mode);
  };

  const topBarClickAction = (data) => {
    if (data == "multiBtn1") {
      navigate("/withdraw", { replace: false });
    }
  };

  const onIDClicked = (id) => {
    if (id != "") {
      copyText(id);
      showToast("ID Copied!", 2500, 0, 1);
    }
  }

  const updateLoadingStatus = (data) => {
    setShowLoading(data);
  };

  const updateRecordList = (data) => {
    let tempData = [];

    for (let i = 0; i < data.length; i++) {
      tempData.push(
        <div key={i} className="col-view pd-10-15 mg-t-20 br-10 bg-white ovf-hidden">
          <div className='row-view' onClick={() => onIDClicked(data[i]['m_id'])}>
            <span className="cl-grey ft-sz-16">UID: {data[i]["m_id"]}</span>
            <img
              className="h-w-15 mg-l-10"
              src={require("../media/icons/copy_icon.png")}
            />
          </div>

          <div className="h-2-p w-100 mg-t-10 bg-l-grey"></div>

          <div className="row-view sb-view mg-t-10">
            <span className="ft-sz-16">Level</span>
            <span className="cl-grey ft-sz-16">{data[i]["m_account_level"]}</span>
          </div>

          <div className="row-view sb-view mg-t-10">
            <span className="ft-sz-16">Deposit amount</span>
            <span className="cl-grey ft-sz-16">{data[i]["m_deposit_amount"]}</span>
          </div>

          <div className="row-view sb-view mg-t-10">
            <span className="ft-sz-16">Bet amount</span>
            <span className="cl-grey ft-sz-16">{data[i]["m_bet_amount"]}</span>
          </div>

          {/* <div className="row-view sb-view mg-t-10">
            <span className="ft-sz-16">Commission</span>
            <span className="cl-grey ft-sz-16">{data[i]["m_joined"]}</span>
          </div> */}

          <div className="row-view sb-view mg-t-10">
            <span className="ft-sz-16">Time</span>
            <span className="cl-grey ft-sz-16">{data[i]["m_joined"]}</span>
          </div>
        </div>
      );
    }

    setConstants((previousState) => {
      return { ...previousState, recordList: tempData };
    });
  };

  function getAllMembers() {
    const requestAPI = async (url, formData) => {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Route: "route-subordinate-records",
            AuthToken: generateAuthToken(Number(getStorage("uid"))),
          },
          body: JSON.stringify(formData),
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if ((data.status_code = "success")) {
          setTotalDepositAmount(data.total_recharge);
          setTotalDepositNumber(data.total_no_recharge);
          setTotalBetAmount(data.total_bet);
          setTotalBetNumber(data.total_no_bet);
          setTotalFirstDepositAmount(data.total_first_deposit);
          setTotalFirstDepositNumber(data.total_no_first_deposit);

          updateRecordList(data.data);
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    };

    updateLoadingStatus(true);
    const formData = {
      USER_ID: getStorage("uid"),
      PAGE_NUM: 1,
    };
    requestAPI(API_ACCESS_URL, formData);
  }

  useEffect(() => {
    if (!getStorage("uid")) {
      if (deleteStorage()) {
        navigate("/login", { replace: true });
      }
    } else {
      getAllMembers();
    }
  }, []);

  return (
    <div className="v-center bg-black">
      <div className="h-100vh pr-v res-wth ovf-scrl-y hide-sb app-bg">
        <ToastDialog onToastChange={onToastChange} ref={toastDialogRef} />
        <TopBar
          intentData={pageConst}
          multiBtn={true}
          multiBtn1=""
          multiBtn2=""
          updateState={topBarClickAction}
        />
        <LoadingDialog intentData={showLoading} />

        <div className="col-view mg-t-45">
          <div className="col-view br-right-t br-left-t mg-b-15">
            <div className="col-view pd-5-15">
              <div className={[general.pd10px, general.borderRadius10px, general.bgCustomColor1].join(" ")}>
                <div className="row-view a-flex-start ">
                  <div className="w-50 col-view a-center pd-5 cl-white br-r-grey">
                    <div>
                      {depositNumber}
                    </div>

                    <div>
                      Deposit number
                    </div>

                    <div className="mg-t-10">
                      {betNumber}
                    </div>

                    <div>
                      Number of bettors
                    </div>

                    <div className="mg-t-10">
                      {firstDeposit}
                    </div>

                    <div className="txt-a-center">
                      Number of people making first deposit
                    </div>
                  </div>

                  <div className="w-50 col-view a-center pd-5 cl-white">
                    <div>
                      {depositAmount}
                    </div>

                    <div>
                      Deposit amount
                    </div>

                    <div className="mg-t-10">
                      {betAmount}
                    </div>

                    <div>
                      Total bet
                    </div>

                    <div className="mg-t-10">
                      {firstDepositAmount}
                    </div>

                    <div className="txt-a-center">
                      First deposit amount
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-view">{pageConst.recordList}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubordinateData;